import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const KW51 = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #c8123d, #e3093c, #f22b59, #e3093c, #c8123d )",
        icons: "#c8123d",
        navClass: "kw51",
      }}
      seo={{
        title: "KW51",
        headerTitle: "kw51",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/kw51_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/kw51/",
      }}
    >
      <PortfolioHeader name="kw51" height="276" />
      <section className="container-fluid kw51_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                Kazimierza <br /> Wielkiego 51
              </h1>
              <ul>
                <li>Logo</li>
                <li>Website</li>
                <li>Advertising materials</li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="inner">
              <p>
                The developer already had a website created by another company
                and planned to change the agency that was responsible for
                generating sales leads. We carried out an audit of the campaign,
                which showed that the campaign was poorly managed In addition,
                the created website did not reflect the values ​​of the project
                in any way, but together with the Client we agreed that the test
                month of the campaign will be made to verify whether the
                improvement of the campaign itself would affect the achievement
                of the intended goals. Unfortunately, the test month confirmed
                our suspicions. The website was supposed to present the
                atmosphere of Japan, while the only thing that referred to Japan
                was the illegible logo and a cherry on the visualizations
                provided by the developer. Blue and pink colors were completely
                not suited to the theme of the project that was supposed to be
                tied to Japan.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_3">
        <div className="row">
          <div className="col-md-8">
            <div className="inner">
              <h3>Logotype</h3>
              <p>
                We approached the project completely from scratch. When
                designing the new logo, we focused on the simplicity and the
                possibility of using the logo on the building, integrating the
                Japanese font into the city identification regarding the
                markings of Krakow buildings.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="conteiner-fluid kw51_section_4">
        <div className="row">
          <div className="col-md-2 offset-md-2">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_old_logo.png")}
              alt=""
            />
            <p>Previous logo</p>
          </div>
          <div className="col-md-4 offset-md-3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_new_logo.png")}
              alt=""
            />
            <p>New logo</p>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_5">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_logo_belt.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../../assets/img/portfolio/kw51_new_logo.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>White CMYK</p>
                    <h5>
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                      <CountUp start={0} duration={3} end={isVisible ? 0 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 255 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>ffffff</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_6">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../../assets/img/portfolio/kw51_fullpage.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_7">
        <div className="row">
          <div className="col-md-5 col_1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_1.png")}
              alt=""
            />
            <div className="inner offset-md-2">
              <p>
                We have shown how the values ​​of flats have changed over the
                years, why Krowodrza is interesting for investments, and
                presented data on tourist traffic in Krakow. We described the
                standard, design and nature of the developed facility. We have
                created a completely new apartment presentation system and
                showed why the potential buyers can trust the real-estate
                developer responsible for the project. The website gained
                transparency and began to build positive experiences among
                users, which we observed in the recordings of the sessions of
                users visiting the website and their behaviour.
              </p>
            </div>
          </div>

          <div className="col-md-5 col_2 offset-md-1">
            <div className="inner">
              <h3>Website</h3>
              <p>
                The website has been rebuilt not only in the visual aspect. We
                have developed a completely new investment communication
                strategy and greatly expanded the location tab, showing all the
                key aspects of the investment project. Additionally, we have
                created a tab directed specifically to investors, where we have
                presented information on why it is worth to invest in Kraków.
              </p>
            </div>
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_2.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_8">
        <div className="row">
          <div className="col-md-7">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_3.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 ">
            <div className="inner">
              <h3>Advertising campaign</h3>
              <p>
                For the purposes of the project, we have developed a unique Key
                Visual accompanying the project in all graphic materials.
                <br />
                <br />
                For the new website we've launched a campaign targeted at
                investors and customers looking for a flat. The apartments were
                not the cheapest, as we oscillated in the range of 11,300 -
                13,900 PLN per m2, which is why our campaign was targeted mainly
                at clients who bought flats for investment purposes. The
                situation in Poland in the period of 02.2020 - 12.2020 was not
                favorable for the developer due to the pandemic crisis, but the
                leads gained through the new website allowed for partial
                reservations of the apartments, contact with more parties
                interested in the project, and building a base of investors,
                which could never happen without a complete makeover.
                <br />
                <br />
                On the basis of the campaigns carried out during the pandemic,
                we observed that at that time, apartments between PLN 6,000 and
                8,000 per m2 and houses with a garden near Kraków sold best. Due
                to the huge collapse in the tourist market, apartments in the
                center were not as popular as before February 2020, when
                investors were mainly interested in ROI and not the price per m2
                because Booking and AirBnB were driving customers their way well
                enough.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_9">
        <div className="row">
          <div className="col-md-5 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_4.png")}
              alt=""
            />
          </div>
          <div className="col-md-5 col_3 offset-md-1">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="conteiner-fluid kw51_section_10">
        <div className="row">
          <div className="col-md-10">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_6.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_11">
        <img
          className="img-fluid"
          src={require("../../../assets/img/portfolio/kw51_img_7.png")}
          alt=""
        />
      </section>
      <section className="container-fluid kw51_section_12">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_8.png")}
              alt=""
            />
          </div>
          <div className="col-md-4 offset-md-1">
            <div className="inner">
              <h3>Outdoor Ads</h3>
              <p>
                To support the sales office, we have prepared an advertising
                brochure containing the most important information about the
                project.
                <br />
                <br />
                In addition, we have prepared a number of outdoor advertisements
                that were designed to inform the potential customers and
                investors about the progress of construction works and encourage
                them to visit the website.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid kw51_section_13">
        <div className="row">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/kw51_img_9.png")}
              alt=""
            />
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default KW51;
